/* eslint-disable no-use-before-define, no-useless-escape */
import { isDev } from "./utils/helpers"



var Countly = Countly || {}
Countly.q = Countly.q || []
Countly.app_key = '0784dd25e5877fef8d63c0b48d997ed5dcb0942e'
Countly.url = 'https://intelibly-4b4e8a93f3259.flex.countly.com'
Countly.q.push(['track_sessions'])
Countly.q.push(['track_pageview'])
Countly.q.push(['track_clicks'])
Countly.q.push(['track_scrolls'])
Countly.q.push(['track_links'])
Countly.q.push(['track_forms'])
Countly.q.push(['collect_from_forms'])

// Check if gclid parameter exists in the URL
function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

var gclidValue = getParameterByName('gclid')

if (gclidValue) {
  // Push gclid value to Countly
  Countly.q.push(['userData.set', 'gclid', gclidValue])
  Countly.q.push(['userData.save'])
}

//load countly script asynchronously when not in development mode
if (!isDev) {
  (function () {
    var cly = document.createElement('script')
    cly.type = 'text/javascript'
    cly.async = true
    cly.src =
      'https://intelibly-4b4e8a93f3259.flex.countly.com/sdk/web/countly.min.js'
    cly.onload = function () {
      Countly.init()
    }
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(cly, s)
  })()
}
